// Colors
$black: #000000;
$lightBlack: #303E5B;
$white: #ffffff;
$lightBlue: #DFF6F4;
$lightGreen: #60D2C7;
$lightPurple: #F0F5FD;
$purple: #7673F6;
$blue-gradient: 'gradient(20.81deg, #60D2C4 3.2%, #4EAAF8 59.18%, #7673F6 107.76%);';

// Font Weights
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;

// Font Families
$muli: 'Mulish';
$karla: 'Karla';


//media query
$desktopHD: 1920px;
$desktopMedium: 1600px;
$largeDevices: 1200px;
$mediumDevices: 992px;
$smallDevices: 768px;
$extraSmall: 576px;

html {
  font-size: 18px;
  overflow-x: hidden;

  @media (max-width: $desktopHD) {
    font-size: 16px;
  }

  @media (max-width: $desktopMedium) {
    font-size: 14px;
  }

  @media (max-width: 1500px) {
    font-size: 12px;
  }

  @media (max-width: $largeDevices) {
    font-size: 10px;
  }

  @media (max-width: $mediumDevices) {
    font-size: 8px;
  }

  @media (max-width: $smallDevices) {
    font-size: 6px;
  }

  @media (max-width: $extraSmall) {
    font-size: 18px;
  }
}

* {
  font-family: $muli;
  outline: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  list-style: none;
  //border: none;
  letter-spacing: normal;
  box-sizing: border-box;
}

button {
  border: none;
}

// container classes

.container {
  margin: 0 auto;
  width: 78%;
  max-width: 1920px;
}

.container-left-offset {
  margin-left: 11%;
  margin-right: auto;
  width: 89%;
  max-width: 1920px;
}

.container-right-offset {
  margin-right: 11%;
  margin-left: auto;
  width: 89%;
  max-width: 1920px;
}

// text classes
.big-title {
  font: $bold 3.5rem $muli;
  color: $lightBlack;
}

.title {
  font: $bold 2.6rem $muli;
  color: $lightBlack;
}

.sub-title {
  font: $bold 1.5rem $muli;
  color: $lightBlack;
}

.paragraph {
  font: $bold 1.4rem $muli;
  color: $lightBlack;
}

.paragraph2 {
  font: $regular 1rem $muli;
  color: $lightBlack;
}


//buttons-styles
.primary-btn {
  padding: 0.7rem 3.8rem;
  font: $semi-bold 1rem $muli;
  color: $white;
  cursor: pointer;
  background: linear-gradient(84.61deg, #5ECECA -7.34%, #50AFF2 44.99%, #6887F7 106.67%);
  border-radius: 1.5rem;

  &.transparent {
    border: 1px solid #7673F6;
    background: transparent;
    color: #7673F6;
    font-weight: 600;
  }
}

.loc {
  margin-right: 1rem;
}

.primary-btn-transparent {
  border: 1px solid $white;
  padding: 0.7rem 3.8rem;
  font: $semi-bold 1rem $muli;
  color: $white;
  cursor: pointer;
  background: transparent;
  border-radius: 1.5rem;
  transition: 0.3s all;

  //&:hover {
  //  background: linear-gradient(84.61deg, #5ECECA -7.34%, #50AFF2 44.99%, #6887F7 106.67%);
  //  color: white;
  //}
}

.secondary-btn {
  background: #E6EBF7;
  padding: 0.7rem 3.8rem;
  font: $semi-bold 1rem $muli;
  color: #A8B4D1;
  cursor: pointer;
  border-radius: 1.5rem;
}

//material-icons styles
.material-icons {
  font-family: 'Material Icons';
  position: relative;
  top: 0.27rem;
}

//Input-item

.data-input {
  width: 100%;
  padding: 0.7rem 0.8rem;
  background: $white;
  border: 1px solid $lightPurple;
  box-shadow: 0 0.3rem 3.5rem rgba(0, 0, 0, 0.07);
  border-radius: 0.2rem;
  color: #A8B4D1;
}


select.default-select {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

//styles for checkbox
.text-field {
  display: flex;

  p {
    margin-left: 20px;

    span {
      color: #60D2C7
    }
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    margin: 0;
    height: 1.2rem;
    width: 1.2rem;
    border: 1px solid #7673F6;
    box-sizing: border-box;
    border-radius: 0.3rem;
    outline: none;
    padding: 0.4rem;
    cursor: pointer;


    &:checked {
      border: none;
      height: 1.2rem;
      width: 1.2rem;
      background: linear-gradient(88.92deg, #5ECECA -7.34%, #50AFF2 44.99%, #6887F7 106.67%);
      border-radius: 0.3rem;
    }

    &:checked::before {
      border: 0.11rem solid $white;
      border-top: none;
      border-right: none;
      content: "";
      height: 0.33rem;
      left: 0.2rem;
      position: absolute;
      top: 0.2rem;
      transform: rotate(-45deg);
      width: 0.6rem;
      background: transparent;
    }
  }
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::ng-deep.mat-snack-bar-container {
  min-width: 12rem !important;
  background: #303E5B;
  position: absolute;
  color: #F0F5FD;
  bottom: 0;
  right: 45%;

  .mat-simple-snackbar-action button {
    display: none;
  }

  .mat-simple-snackbar {
    justify-content: center;
  }

}

.select-arrow {
  margin: 0 -1.6rem;
}

.code-country {
  z-index: 99;
  line-height: 25px;
  font-size: 0.9rem;
  padding: 2px;
  margin-left: 10px;
  position: absolute;
  font-weight: bold;
  top: 39%;
}

.phone-number-code {
  text-indent: 2.8rem;
}

.max-words {
  color: #6A7794;
  font-size: 0.7rem;
  position: absolute;
  right: 20%;
  bottom: 1.6rem;
}

textarea {
  resize: none !important;
}

::ng-deep.mat-form-field-underline {
  display: none;
}

::ng-deep.mat-form-field-infix {
  padding: 0 0 0 0 !important;
  border-top: 0 !important;
}

::ng-deep.mat-form-field-appearance-legacy {
  padding: 0 0 0 0 !important;
}

::ng-deep .mat-form-field-wrapper {
  padding: 0 0 0 0 !important;
}

.star {
  color: #DE3B29;
}

input[type="checkbox"][readonly] {
  pointer-events: none;
}

.scroll-wrap {
  height: 12rem;
  overflow: scroll;
}

.scroll-wrap-skill {
  height: 13rem;
  overflow: scroll;
}

::ng-deep div.cdk-overlay-pane {
  width: fit-content !important;
  padding: 10px;
}
