html {
  font-size: 18px;
  overflow-x: hidden;
}
@media (max-width: 1920px) {
  html {
    font-size: 16px;
  }
}
@media (max-width: 1600px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 1500px) {
  html {
    font-size: 12px;
  }
}
@media (max-width: 1200px) {
  html {
    font-size: 10px;
  }
}
@media (max-width: 992px) {
  html {
    font-size: 8px;
  }
}
@media (max-width: 768px) {
  html {
    font-size: 6px;
  }
}
@media (max-width: 576px) {
  html {
    font-size: 18px;
  }
}

* {
  font-family: "Mulish";
  outline: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  list-style: none;
  letter-spacing: normal;
  box-sizing: border-box;
}

button {
  border: none;
}

.container {
  margin: 0 auto;
  width: 78%;
  max-width: 1920px;
}

.container-left-offset {
  margin-left: 11%;
  margin-right: auto;
  width: 89%;
  max-width: 1920px;
}

.container-right-offset {
  margin-right: 11%;
  margin-left: auto;
  width: 89%;
  max-width: 1920px;
}

.big-title {
  font: 700 3.5rem "Mulish";
  color: #303E5B;
}

.title {
  font: 700 2.6rem "Mulish";
  color: #303E5B;
}

.sub-title {
  font: 700 1.5rem "Mulish";
  color: #303E5B;
}

.paragraph {
  font: 700 1.4rem "Mulish";
  color: #303E5B;
}

.paragraph2 {
  font: 400 1rem "Mulish";
  color: #303E5B;
}

.primary-btn {
  padding: 0.7rem 3.8rem;
  font: 600 1rem "Mulish";
  color: #ffffff;
  cursor: pointer;
  background: linear-gradient(84.61deg, #5ECECA -7.34%, #50AFF2 44.99%, #6887F7 106.67%);
  border-radius: 1.5rem;
}
.primary-btn.transparent {
  border: 1px solid #7673F6;
  background: transparent;
  color: #7673F6;
  font-weight: 600;
}

.loc {
  margin-right: 1rem;
}

.primary-btn-transparent {
  border: 1px solid #ffffff;
  padding: 0.7rem 3.8rem;
  font: 600 1rem "Mulish";
  color: #ffffff;
  cursor: pointer;
  background: transparent;
  border-radius: 1.5rem;
  transition: 0.3s all;
}

.secondary-btn {
  background: #E6EBF7;
  padding: 0.7rem 3.8rem;
  font: 600 1rem "Mulish";
  color: #A8B4D1;
  cursor: pointer;
  border-radius: 1.5rem;
}

.material-icons {
  font-family: "Material Icons";
  position: relative;
  top: 0.27rem;
}

.data-input {
  width: 100%;
  padding: 0.7rem 0.8rem;
  background: #ffffff;
  border: 1px solid #F0F5FD;
  box-shadow: 0 0.3rem 3.5rem rgba(0, 0, 0, 0.07);
  border-radius: 0.2rem;
  color: #A8B4D1;
}

select.default-select {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

.text-field {
  display: flex;
}
.text-field p {
  margin-left: 20px;
}
.text-field p span {
  color: #60D2C7;
}
.text-field input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  margin: 0;
  height: 1.2rem;
  width: 1.2rem;
  border: 1px solid #7673F6;
  box-sizing: border-box;
  border-radius: 0.3rem;
  outline: none;
  padding: 0.4rem;
  cursor: pointer;
}
.text-field input:checked {
  border: none;
  height: 1.2rem;
  width: 1.2rem;
  background: linear-gradient(88.92deg, #5ECECA -7.34%, #50AFF2 44.99%, #6887F7 106.67%);
  border-radius: 0.3rem;
}
.text-field input:checked::before {
  border: 0.11rem solid #ffffff;
  border-top: none;
  border-right: none;
  content: "";
  height: 0.33rem;
  left: 0.2rem;
  position: absolute;
  top: 0.2rem;
  transform: rotate(-45deg);
  width: 0.6rem;
  background: transparent;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::ng-deep.mat-snack-bar-container {
  min-width: 12rem !important;
  background: #303E5B;
  position: absolute;
  color: #F0F5FD;
  bottom: 0;
  right: 45%;
}
::ng-deep.mat-snack-bar-container .mat-simple-snackbar-action button {
  display: none;
}
::ng-deep.mat-snack-bar-container .mat-simple-snackbar {
  justify-content: center;
}

.select-arrow {
  margin: 0 -1.6rem;
}

.code-country {
  z-index: 99;
  line-height: 25px;
  font-size: 0.9rem;
  padding: 2px;
  margin-left: 10px;
  position: absolute;
  font-weight: bold;
  top: 39%;
}

.phone-number-code {
  text-indent: 2.8rem;
}

.max-words {
  color: #6A7794;
  font-size: 0.7rem;
  position: absolute;
  right: 20%;
  bottom: 1.6rem;
}

textarea {
  resize: none !important;
}

::ng-deep.mat-form-field-underline {
  display: none;
}

::ng-deep.mat-form-field-infix {
  padding: 0 0 0 0 !important;
  border-top: 0 !important;
}

::ng-deep.mat-form-field-appearance-legacy {
  padding: 0 0 0 0 !important;
}

::ng-deep .mat-form-field-wrapper {
  padding: 0 0 0 0 !important;
}

.star {
  color: #DE3B29;
}

input[type=checkbox][readonly] {
  pointer-events: none;
}

.scroll-wrap {
  height: 12rem;
  overflow: scroll;
}

.scroll-wrap-skill {
  height: 13rem;
  overflow: scroll;
}

::ng-deep div.cdk-overlay-pane {
  width: fit-content !important;
  padding: 10px;
}